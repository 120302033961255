import React, { useState, useEffect, useRef, useCallback } from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import letterEditableByConstants from '../constants/letterEditableBy.constants'
import wizardService from '../services/wizard.service'
import { downloadFile } from '../utils/file'

import AdminCard from './adminCard'
import AdminDocumentPreview from './adminDocumentPreview'
import ActionBar from './actionBar'
import { useService } from '../contexts/serviceContext'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { stringCompare } from '../utils/sortHelper'
import { enqueueSnackbar } from '../actions/notification.actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles({
  formControl: { marginBottom: '20px', width: 200 },
  radioControl: { marginBottom: '20px', maxWidth: 600 },
  textbox: { minHeight: 260 },
})

const templateNameMaxCharacters = 150
const templateNameMinCharacters = 3

const DocumentTemplateForm = ({
  initialFormData,
  currentTemplates,
  brandings,
  editables,
  reviewTypes,
  onSubmit,
  fetchEditables,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  //TRUE IF CAME FROM UPDATE PAGE
  //FALSE IF CAME FROM ADD PAGE
  const [isEditMode, setIsEditMode] = useState(true)

  //TEMPLATE NAME
  const [name, setName] = useState('')
  const [nameIsValid, setNameIsValid] = useState(false)
  const [nameValidationMessage, setNameValidationMessage] = useState('')

  //TEMPLATE BRANDING
  const [brandingId, setBrandingId] = useState(0)
  const [branding, setBranding] = useState({})
  const [brandingIsValid, setBrandingIsValid] = useState(false)
  const [brandingValidationMessage, setBrandingValidationMessage] = useState('')

  //TEMPLATE CONTENT
  //CONTENT EDITOR HTML = THE HTML THAT THE ADMIN SEE'S IN THE TELERIK EDITOR
  //PREVIEW HTML = THE HTML THE ADMIN SEE'S IN THE PREVIEW THAT SHOWS VALID AND INVALID EDITABLE REGIONS
  //FINAL HTML = THE HTML THAT IS USED IN THE WIZARD BY THE END USER CONTAINING CSS CLASSES TO THE WIZARD CAN APPLY CSS AND JS
  const [contentEditorHtml, setContentEditorHtml] = useState('')
  const [contentEditorHtmlIsValid, setContentEditorHtmlIsValid] = useState(
    false
  )
  const [
    contentEditorHtmlValidationMessage,
    setContentEditorHtmlValidationMessage,
  ] = useState('')
  const contentEditorWrapperRef = useRef()
  const contentEditorRef = useRef()
  const [previewHtml, setPreviewHtml] = useState('')
  const [testDataHtml, setTestDataHtml] = useState('')
  const [testDataMode, setTestDataMode] = useState(false)
  const [finalHtml, setFinalHtml] = useState('')
  const [validEditables, setValidEditables] = useState([])
  const [invalidEditables, setInvalidEditables] = useState([])
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false)
  const [isScheduled, setIsScheduled] = useState(false)
  const [scheduledDate, setScheduledDate] = useState(new moment())
  const [reviewTypeId, setReviewTypeId] = useState(initialFormData.reviewTypeId)
  //TEMPLATE FLAGS
  const [isArchived, setIsArchived] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [isAnytime, setIsAnytime] = useState(initialFormData.isAnytime)
  const [isVisibleToProcessEngineBusinessUser, setIsVisibleToProcessEngineBusinessUser] = useState(initialFormData.isVisibleToProcessEngineBusinessUser)
  const [letterEditableBy, setLetterEditableBy] = useState(letterEditableByConstants.No);

  //FORM STEPS
  const [steps, setSteps] = useState([])
  const [activeStep, setActiveStep] = useState(0)
  const [isActiveStepValid, setIsActiveStepValid] = useState(false)

  //SELECTABLE EDITABLES FOR THE TELERIKE CUSTOM DROP DOWN
  const [sortedAndFilteredEditables, setSortedAndFilteredEditables] = useState(
    []
  )

  //STRING TEMPLATE FOR TELERIK EDITOR CUSTOM DROP DOWN
  const [selectEditableListTemplate, setSelectEditableListTemplate] = useState(
    ''
  )

  useEffect(() => {
    setBranding(brandings.find(x => x.id === brandingId))
  }, [brandings, brandingId])

  useEffect(() => {
    setIsEditMode(initialFormData.id > 0)
    setName(initialFormData.name)
    setBrandingId(initialFormData.brandingId)
    setReviewTypeId(initialFormData.reviewTypeId)
    setContentEditorHtml(initialFormData.adminContentHtml)
    setLetterEditableBy(initialFormData.letterEditableBy)
    if (initialFormData.liveDate) {
      setIsScheduled(true)
      setScheduledDate(initialFormData.liveDate)
    }
  }, [initialFormData])
  //VALIDATE THE CURRENT STEP IN THE FORM
  //STEP 0 - IS THE NAME VALID?
  //STEP 1 - IS THE BRANDING VALID?
  //STEP 3 - IS THE CONTENT EDITOR CONTENT VALID?
  const validateActiveStep = useCallback(() => {
    if (activeStep === 0 && nameIsValid) return true
    if (activeStep === 1 && brandingIsValid) return true
    if (activeStep === 2 && contentEditorHtmlIsValid) return true
    return false
  }, [activeStep, nameIsValid, brandingIsValid, contentEditorHtmlIsValid])

  //CHANGE STEP NAMES DEPENDING ON IF WE ARE ADDING / UPDATING A TEMPLATE
  useEffect(() => {
    setSteps(
      isEditMode
        ? [
          'Update template',
          'Update the branding',
          'Update the content',
          'Preview and confirm',
        ]
        : [
          'Create a template',
          'Select the branding',
          'Add some content',
          'Preview and confirm',
        ]
    )
  }, [isEditMode])

  //SAVE NEW/UPDATED TEMPLATE
  const saveTemplate = ({ isPublished }) => {
    if (!isValid) return

    const template = {
      id: initialFormData.id,
      name: name,
      serviceId: initialFormData.serviceId,
      isArchived,
      contentHtml: finalHtml,
      adminContentHtml: contentEditorHtml,
      brandingId,
      isPublished,
      editableRegionIds: validEditables.map(editable => editable.id),
      liveDate: isScheduled ? scheduledDate : null,
      isAnytime: isAnytime,
      isVisibleToProcessEngineBusinessUser: isVisibleToProcessEngineBusinessUser,
      reviewType: reviewTypeId,
      letterEditableBy: letterEditableBy
    }

    onSubmit(template)
  }

  const handleIsAnytime = e => {
    setIsAnytime(e.target.value === 'true' ? true : false)
  }

  const handleIsVisibleToProcessEngineBusinessUser = e => {
    setIsVisibleToProcessEngineBusinessUser(e.target.value === 'true' ? true : false)
  }

  const handleLetterEditableByChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setLetterEditableBy(newValue);
  }


  //FILTER AND SORT THE EDITABLES TO USE
  useEffect(() => {
    var enabledEditables = editables.filter(e => !e.isArchived)
    var sortedEditables = enabledEditables.sort((a, b) =>
      stringCompare(a.name, b.name, 'asc')
    )
    setSortedAndFilteredEditables(sortedEditables)
  }, [editables])

  //BUILD THE STRING TEMPLATE FOR TELERIK EDITOR CUSTOM DROP DOWN
  useEffect(() => {
    var selectEditableList = document.createElement('select')
    selectEditableList.id = 'selectEditableList'
    var defaultOption = document.createElement('option')
    defaultOption.text = 'Editables'
    defaultOption.value = ''
    selectEditableList.add(defaultOption)
    sortedAndFilteredEditables.forEach(e => {
      var editableOption = document.createElement('option')
      editableOption.text = e.name
      editableOption.value = `\\#\\#${e.name}\\#\\#`
      selectEditableList.add(editableOption)
    })
    var textualValue = selectEditableList.outerHTML
    setSelectEditableListTemplate(textualValue)
  }, [sortedAndFilteredEditables])

  //TOOLS TO SHOW IN THE TELERIK EDITOR
  const editorTools = [
    'bold',
    'italic',
    'underline',
    'justifyLeft',
    'justifyCenter',
    'justifyRight',
    'justifyFull',
    'insertUnorderedList',
    'insertOrderedList',
    'indent',
    'outdent',
    'insertImage',
    'tableWizard',
    'createTable',
    'addRowAbove',
    'addRowBelow',
    'addColumnLeft',
    'addColumnRight',
    'deleteRow',
    'deleteColumn',
    'mergeCellsHorizontally',
    'mergeCellsVertically',
    'splitCellHorizontally',
    'splitCellVertically',
    'viewHtml',
    'formatting',
    'cleanFormatting',
    'copyFormat',
    'applyFormat',
    'fontName',
    'fontSize',
    'foreColor',
    'backColor',
    {
      name: 'editableList',
      tooltip: 'editableList',
      template: selectEditableListTemplate,
    },
  ]

  const onContentEditorChange = () => contentEditorChanged()

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const contentEditorChanged = () => {
    let $ = window.jQuery
    const editor = $(contentEditorRef.current).data('kendoEditor')
    if (editor) setContentEditorHtml(editor.value())
  }
  const { serviceConfig } = useService()

  //SETUP TELERIK EDITOR WHEN WE ARE ON STEP 2
  //SETUP CUSTOM DROP DOWN LIST
  useEffect(() => {
    if (activeStep === 2) {
      let $ = window.jQuery

      $(contentEditorRef.current).kendoEditor({
        stylesheets: [
          `/css/editor/font-family-${serviceConfig.fontFamily}.css`,
          `/css/editor/font-size-${serviceConfig.fontSize}.css`,
        ],
        pasteCleanup: {
          all: false,
          css: true,
          custom: null,
          keepNewLines: false,
          msAllFormatting: true,
          msConvertLists: false,
          msTags: true,
          none: false,
          span: true
        },
        tools: editorTools,
        change: onContentEditorChange,
        select: onContentEditorChange,
        execute: onContentEditorChange,
        paste: onContentEditorChange,
      })
      var editor = $(contentEditorRef.current).data('kendoEditor')
      editor.value(contentEditorHtml)
      contentEditorWrapperRef.current.style.display = 'block'

      // Apply default text alignment to justify for all <p> tags directly within the iframe document
      if (editor && isInitialLoad) {
        $(editor.body).find('p').css('text-align', 'justify');
        setIsInitialLoad(false);
      }

      $('#selectEditableList').kendoDropDownList({
        change: function (e) {
          const tagToInsert = e.sender.value()
          const editor = $(contentEditorRef.current).data('kendoEditor')

          const hashTags = getHashTagsUsed(editor.value())
          if (hashTags) {
            if (hashTags.includes(tagToInsert)) {
              dispatch(
                enqueueSnackbar({
                  message:
                    'Editables can only be used once each in a template.',
                  options: {
                    variant: 'info',
                  },
                })
              )

              return
            }
          }

          editor.exec('inserthtml', {
            value: tagToInsert,
          })
          this.value('')
        },
      })
    }
  }, [activeStep])

  //GET ALL THE HASH TAGS IN THE DOCUMENT E.G. #NAME#
  const getHashTagsUsed = editorHtml => editorHtml.match(/##(.+?)##/gi)

  //REMOVE THE # FOR THE END USER
  const stripHashTags = text => text.replace(/[#]/g, '')

  //APPLY STYLE TO BE USED IN THE ADMINS PREVIEW
  //BLUE = VALID, RED = INVALID
  const getStyle = isEditable => {
    return !isEditable
      ? `background-color: #e74c3c; border-radius: 4px; color: #ffffff; padding: 4px 8px; word-wrap: break-word;`
      : `background-color: #1abc9c; border-radius: 4px; color: #ffffff; padding: 4px 8px; word-wrap: break-word;`
  }

  //GET ALL HASH TAGS IN THE TEMPLATE
  //CHECK THEY ARE IN THE CUSTOM DROP DOWN LIST AND APPLY A VALID/INVALID STYLE
  //SET THE PREVIEW HTML WHICH CONTAINS THESE SYLES
  const updatePreviewHtml = useCallback(
    html => {
      var hashTagsInDocument = getHashTagsUsed(html)
      var validEditables = []
      var invalidEditables = []

      if (hashTagsInDocument) {
        hashTagsInDocument.forEach(ht => {
          var editable = sortedAndFilteredEditables.find(
            er => `##${er.name}##` === ht
          )
          if (editable) {
            validEditables.push(editable)
          } else {
            invalidEditables.push(ht)
          }
          var span = `<span style="${getStyle(editable)}">${stripHashTags(
            ht
          )}</span>`
          html = html.replace(ht, span)
        })
      }
      setValidEditables(validEditables)
      setInvalidEditables(invalidEditables)
      setPreviewHtml(html)
    },
    [sortedAndFilteredEditables]
  )

  //GET ALL HASH TAGS IN THE TEMPLATE
  //CHECK THEY ARE IN THE CUSTOM DROP DOWN LIST AND REPLACE THEM WITH TEST DATA
  //SET THE TEST DATA HTML WHICH CONTAINS THIS TEST DATA
  const updateTestDataHtml = useCallback(
    html => {
      const baseUrl = window.location.origin
      var hashTagsInDocument = getHashTagsUsed(html)
      if (hashTagsInDocument) {
        hashTagsInDocument.forEach(ht => {
          var editable = editables.find(er => `##${er.name}##` === ht)
          if (editable) {
            var url = `${baseUrl}/admin/editable/${editable.id}`
            var link = `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: inherit; text-decoration: none;">${editable.testData}</a>`
            html = html.replace(ht, link)
          }
        })
      }
      setTestDataHtml(html)
    },
    [editables]
  )

  //SETUP THE FINAL HTML WHICH ADDS CLASSES AND SPANS FOR JS/CSS USE IN THE MANAGERS VIEW
  const updateFinalHtml = useCallback(
    html => {
      var hashTagsInDocument = getHashTagsUsed(html)
      if (hashTagsInDocument) {
        hashTagsInDocument.forEach(ht => {
          var editable = editables.find(er => `##${er.name}##` === ht)
          if (editable) html = html.replace(ht, editable.contentHtml)
        })
      }
      setFinalHtml(html)
    },
    [editables]
  )

  useEffect(() => {
    updatePreviewHtml(contentEditorHtml)
    updateFinalHtml(contentEditorHtml)
    updateTestDataHtml(contentEditorHtml)
  }, [contentEditorHtml, updatePreviewHtml, updateFinalHtml, updateTestDataHtml])

  useEffect(() => {
    if (name.length < templateNameMinCharacters) {
      setNameValidationMessage(
        `Enter at least ${templateNameMinCharacters} characters`
      )
      setNameIsValid(false)
    } else if (name.length > templateNameMaxCharacters) {
      setNameValidationMessage(
        `Enter ${templateNameMaxCharacters} or fewer characters`
      )
      setNameIsValid(false)
    } else if (
      currentTemplates.find(x => x.name.toLowerCase() === name.toLowerCase())
    ) {
      setNameValidationMessage('This name has already been taken')
      setNameIsValid(false)
    } else {
      setNameIsValid(true)
      setNameValidationMessage('Valid Input')
    }
    setBrandingIsValid(brandingId > 0)
    setContentEditorHtmlIsValid(contentEditorHtml.length > 10)
  }, [name, brandingId, contentEditorHtml, currentTemplates])

  useEffect(() => {
    setNameValidationMessage(nameValidationMessage)
    setBrandingValidationMessage(
      brandingIsValid ? 'Valid Input' : 'Select a branding'
    )
    setContentEditorHtmlValidationMessage(
      contentEditorHtmlIsValid ? 'Valid Input' : 'Enter a template'
    )
    setIsValid(
      nameIsValid &&
      brandingIsValid &&
      contentEditorHtmlIsValid &&
      invalidEditables.length === 0
    )
    setIsActiveStepValid(() => validateActiveStep())
  }, [
    nameIsValid,
    brandingIsValid,
    contentEditorHtmlIsValid,
    activeStep,
    invalidEditables,
    contentEditorHtml,
    nameValidationMessage,
    validateActiveStep,
  ])

  const previewDocument = async () => {
    try {
      wizardService
        .getPdfPreviewWithCustomHeaders({
          documentHtml: testDataHtml,
          headerHtml: branding.headerHtml,
          footerHtml: branding.footerHtml
        })
        .then(response => {
          downloadFile(response)
        })
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'Could not download preview. Please try again.',
          options: {
            variant: 'error',
          },
        })
      )
    }
  }

  return (
    <>
      <AdminCard>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </AdminCard>
      <form>
        {activeStep === 0 && (
          <AdminCard title={'Overview'}>
            <>
              <div>
                <FormGroup>
                  <FormControl
                    className={classes.formControl}
                    error={!nameIsValid}>
                    <TextField
                      type='text'
                      label='Name'
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                    <FormHelperText>{nameValidationMessage}</FormHelperText>
                  </FormControl>
                </FormGroup>{' '}
                <FormGroup>
                  <FormControl className={classes.radioControl}>
                    <FormLabel>
                      What is the review type required for this document before it can be completed?
                    </FormLabel>
                    <Select
                      value={reviewTypeId}
                      onChange={e => setReviewTypeId(e.target.value)}>
                      <MenuItem value={0} disabled>
                        Please Select
                      </MenuItem>
                      {reviewTypes.map(rt => (
                        <MenuItem key={rt.id} value={rt.id}>
                          {rt.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormGroup>
                <FormGroup>
                  <FormControl className={classes.radioControl}>
                    <FormLabel>
                      Is this template an Anytime Document?
                    </FormLabel>
                    <RadioGroup
                      value={String(isAnytime)}
                      onChange={handleIsAnytime}>
                      <FormControlLabel
                        value={'true'}
                        control={<Radio />}
                        label='Yes'
                      />
                      <FormControlLabel
                        value={'false'}
                        control={<Radio />}
                        label='No'
                      />
                    </RadioGroup>
                  </FormControl>
                </FormGroup>
                <FormGroup>
                  <FormControl className={classes.radioControl}>
                    <FormLabel>
                      If this template is an Anytime Document, should it be visible to Business Users?
                    </FormLabel>
                    <RadioGroup
                      value={String(isVisibleToProcessEngineBusinessUser)}
                      onChange={handleIsVisibleToProcessEngineBusinessUser}>
                      <FormControlLabel
                        value={'true'}
                        control={<Radio />}
                        label='Yes'
                      />
                      <FormControlLabel
                        value={'false'}
                        control={<Radio />}
                        label='No'
                      />
                    </RadioGroup>
                  </FormControl>
                </FormGroup>
              </div>
              {isEditMode && (
                <div>
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isArchived}
                          onChange={e => setIsArchived(e.target.checked)}
                          color='primary'
                        />
                      }
                      label='Archived?'
                    />
                  </FormControl>
                </div>
              )}
              <FormGroup>
                <FormControl className={classes.radioControl}>
                  <FormLabel>Make the letter editable?</FormLabel>
                  <RadioGroup
                    value={letterEditableBy}
                    onChange={handleLetterEditableByChange}
                  >
                    <FormControlLabel
                      value={letterEditableByConstants.No}
                      control={<Radio />}
                      label='No'
                    />
                    <FormControlLabel
                      value={letterEditableByConstants.HRUsers}
                      control={<Radio />}
                      label='HR Users'
                    />
                    <FormControlLabel
                      value={letterEditableByConstants.BusinessUsers}
                      control={<Radio />}
                      label='Business Users'
                    />
                    <FormControlLabel
                      value={letterEditableByConstants.HRAndBusinessUsers}
                      control={<Radio />}
                      label='HR Users and Business Users'
                    />
                  </RadioGroup>
                </FormControl>
              </FormGroup>
            </>
          </AdminCard>
        )}
        {activeStep === 1 && (
          <AdminCard title={'Branding'}>
            <div>
              <FormControl
                className={classes.formControl}
                error={!brandingIsValid}>
                <InputLabel>Branding</InputLabel>
                <Select
                  value={brandingId}
                  onChange={e => setBrandingId(e.target.value)}>
                  <MenuItem value={0} disabled>
                    Please Select
                  </MenuItem>
                  {brandings.map(br => (
                    <MenuItem key={br.id} value={br.id}>
                      {br.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{brandingValidationMessage}</FormHelperText>
              </FormControl>
            </div>
          </AdminCard>
        )}
        {activeStep === 2 && (
          <AdminCard title={'Content'}>
            <div ref={contentEditorWrapperRef} style={{ display: 'none' }}>
              <textarea style={{ height: '700px' }} ref={contentEditorRef} />
            </div>
            <FormHelperText error={!contentEditorHtmlIsValid}>
              {contentEditorHtmlValidationMessage}
            </FormHelperText>
          </AdminCard>
        )}
        {activeStep === 3 && testDataMode && (
          <AdminDocumentPreview
            headerHtml={branding.headerHtml}
            footerHtml={branding.footerHtml}
            contentHtml={testDataHtml}
          />
        )}
        {activeStep === 3 && !testDataMode && (
          <AdminDocumentPreview
            headerHtml={branding.headerHtml}
            footerHtml={branding.footerHtml}
            contentHtml={previewHtml}
          />
        )}
        <ActionBar
          leftContent={[
            {
              label: 'Back',
              show: activeStep !== 0,
              isPrimaryAction: false,
              onClick: () =>
                setActiveStep(prevActiveStep => prevActiveStep - 1),
            },
            {
              label: 'Next',
              show: activeStep !== steps.length - 1,
              isPrimaryAction: false,
              disabled: !isActiveStepValid,
              onClick: () =>
                setActiveStep(prevActiveStep => prevActiveStep + 1),
            },
          ]}
          rightContent={[
            {
              label: testDataMode ? 'Switch to Preview Mode' : 'Switch to Test Data Mode',
              show: activeStep === steps.length - 1,
              isPrimaryAction: false,
              onClick: () => {
                setTestDataMode(!testDataMode)
                fetchEditables()
              },
            },
            {
              label: 'Download Preview',
              show: activeStep === steps.length - 1,
              isPrimaryAction: false,
              onClick: () => {
                previewDocument()
              },
            },
            {
              label: 'Save as Draft',
              show: activeStep === steps.length - 1,
              isPrimaryAction: false,
              disabled: !isValid,
              type: 'button',
              onClick: () => saveTemplate({ isPublished: false }),
            },
            {
              label: 'Publish',
              show: activeStep === steps.length - 1,
              isPrimaryAction: true,
              disabled: !isValid,
              type: 'button',
              onClick: () => setScheduleDialogOpen(true),
            },
          ]}
        />
      </form>
      <Dialog
        onClose={() => setScheduleDialogOpen(false)}
        aria-labelledby='customized-dialog-title'
        open={scheduleDialogOpen}>
        <DialogTitle
          id='customized-dialog-title'
          onClose={() => setScheduleDialogOpen(false)}>
          Schedule Document
        </DialogTitle>
        <DialogContent dividers>
          <Typography style={{ fontWeight: 'bold' }} gutterBottom>
            All of the test data will be removed prior to the template being published.
          </Typography>
          <Typography gutterBottom>
            Would you like to schedule this template to go live on a specific
            date?
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={isScheduled}
                onChange={e => setIsScheduled(e.target.checked)}
                name='Schedule Document'
              />
            }
            label='Schedule Document'
          />
          {isScheduled && (
            <div>
              <KeyboardDatePicker
                format='DD/MM/YYYY'
                value={scheduledDate}
                disablePast={true}
                onChange={selectedDate => setScheduledDate(selectedDate)}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setScheduleDialogOpen(false)} color='primary'>
            Cancel
          </Button>
          <Button
            autoFocus
            variant='contained'
            color='secondary'
            onClick={() => saveTemplate({ isPublished: true })}>
            Publish
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DocumentTemplateForm
