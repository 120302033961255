import React, { useEffect, useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { Typography } from '@material-ui/core'
import EditableDialogWrapper from './editableDialogWrapper'
import PropTypes from 'prop-types'

const HtmlDialog = ({
  title,
  description,
  open,
  currentHtml,
  onConfirm,
  onReset,
  onClose,
  fontSize,
  fontFamily,
  onChange,
}) => {
  const [hasEntered, setHasEntered] = useState(false)

  const [html, setHtml] = useState(currentHtml)
  const contentEditorWrapperRef = useRef()
  const contentEditorRef = useRef()

  useEffect(() => {
    if (open) {
      setHtml(currentHtml);
    }
  }, [currentHtml, open]);

  const onContentEditorChange = () => contentEditorChanged()

  const contentEditorChanged = () => {
    let $ = window.jQuery
    const editor = $(contentEditorRef.current).data('kendoEditor')
    if (editor) {
      const newValue = editor.value()
      setHtml(newValue)

      if (onChange) {
        onChange(newValue) // Notify the parent component of the change
      }
    }
  }

  useEffect(() => {
    if (hasEntered) {
      let $ = window.jQuery

      if (contentEditorRef.current) {
        $(contentEditorRef.current).kendoEditor({
          stylesheets: [
            `/css/editor/font-family-${fontFamily}.css`,
            `/css/editor/font-size-${fontSize}.css`,
          ],
          tools: [
            'bold', 'italic', 'underline', 'justifyLeft', 'justifyCenter',
            'justifyRight', 'justifyFull', 'insertUnorderedList',
            'insertOrderedList', 'indent', 'outdent', 'insertImage', 'tableWizard',
            'createTable', 'addRowAbove', 'addRowBelow', 'addColumnLeft',
            'addColumnRight', 'deleteRow', 'deleteColumn', 'mergeCellsHorizontally',
            'mergeCellsVertically', 'splitCellHorizontally', 'splitCellVertically',
            'viewHtml', 'formatting', 'cleanFormatting', 'copyFormat', 'applyFormat',
            'foreColor', 'backColor', 'fontName', 'fontSize',
          ],
          change: onContentEditorChange,
          keyup: onContentEditorChange,
          input: onContentEditorChange,
          select: onContentEditorChange,
          execute: onContentEditorChange,
          paste: onContentEditorChange,
        })

        const editor = $(contentEditorRef.current).data('kendoEditor')
        if (editor) {
          editor.value(html)
        }

        contentEditorWrapperRef.current.style.display = 'block'

        return () => {
          const editorInstance = $(contentEditorRef.current).data('kendoEditor')
          if (editorInstance) {
            editorInstance.destroy()
          }
        }
      } else {
        console.error('contentEditorRef is not defined.')
      }
    }
  }, [hasEntered])

  return (
    <>
      <EditableDialogWrapper
        title={title}
        open={open}
        onClose={onClose}
        onEntered={() => setHasEntered(true)}>
        <DialogContent dividers>
          {description && <Typography gutterBottom>{description}</Typography>}
          <div style={{ height: 400 }}>
            <div ref={contentEditorWrapperRef} style={{ display: 'none' }}>
              <textarea style={{ height: 380 }} ref={contentEditorRef} />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onReset} color='secondary'>
            Reset
          </Button>
          <Button
            variant='contained'
            onClick={() => onConfirm(html)}
            color='secondary'>
            Confirm
          </Button>
        </DialogActions>
      </EditableDialogWrapper>
    </>
  )
}

HtmlDialog.propTyes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  currentEditorState: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default HtmlDialog
