import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useService } from '../contexts/serviceContext'
import InputTypes from '../constants/editabletypes.constants'

import {
  checkNameIsValid,
  checkInputTypeIdIsValid,
  checkTestDataIsValid,
} from '../services/editableregion.service'

import AdminCard from './adminCard'
import editabletypesConstants from '../constants/editabletypes.constants'
import HtmlEditor from './editableDialogs/HtmlEditor'

const useStyles = makeStyles({
  formControl: { marginBottom: '20px', width: '100%' },
})

const EditableRegionFormOverview = ({
  id,
  name,
  description,
  inputTypeId,
  nameChanged,
  descriptionChanged,
  testData,
  testDataChanged,
  minLength,
  maxLength,
  typeChanged,
  isEditMode,
  isMandatory,
  isMandatoryChanged,
  isArchived,
  isArchivedChanged,
  currentEditables,
}) => {
  const classes = useStyles()
  const { serviceConfig } = useService()

  const [testDataIsValid, setTestDataIsValid] = useState(false)
  const [nameIsValid, setNameIsValid] = useState(false)
  const [inputTypeIdIsValid, setInputTypeIdIsValid] = useState(false)
  const [descriptionIsDisabled, setDescriptionIsDisabled] = useState(false)

  useEffect(() => {
    setInputTypeIdIsValid(checkInputTypeIdIsValid(inputTypeId))
    setDescriptionIsDisabled(inputTypeId === editabletypesConstants.Address ? true : false)
  }, [inputTypeId])


  useEffect(() => {
    var nameValidity = checkNameIsValid(name, currentEditables)
    setNameIsValid(nameValidity.isValid)
    setNameValidationMessage(nameValidity.message)
  }, [name, currentEditables])

  useEffect(() => {
    var testDataValidity = checkTestDataIsValid(testData, minLength, maxLength, inputTypeId)
    setTestDataIsValid(testDataValidity.isValid)
    setTestDataValidationMessage(testDataValidity.message)
  }, [testData, minLength, maxLength])

  const [testDataValidationMessage, setTestDataValidationMessage] = useState('')
  const [nameValidationMessage, setNameValidationMessage] = useState('')
  const [
    inputTypeIdValidationMessage,
    setInputTypeIdValidationMessage,
  ] = useState('')

  useEffect(() => {
    setInputTypeIdValidationMessage(
      inputTypeIdIsValid ? 'Valid Entry' : 'Must select an option'
    )
  }, [inputTypeIdIsValid])

  return (
    <AdminCard title={'Overview'}>
      <div>
        <FormControl className={classes.formControl} error={!nameIsValid}>
          <TextField
            disabled={isEditMode}
            type='text'
            label='Name'
            value={name}
            onChange={e => nameChanged(e.target.value)}
          />
          <FormHelperText>{nameValidationMessage}</FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl className={classes.formControl}>
          <TextField
            type='text'
            label='Description'
            disabled={descriptionIsDisabled}
            value={!descriptionIsDisabled ? description : 'N/A'}
            onChange={e => descriptionChanged(e.target.value)}
          />
        </FormControl>
      </div>
      <div>
        <FormControl className={classes.formControl} error={!testDataIsValid}>
          {inputTypeId === InputTypes.HtmlEditor || inputTypeId === InputTypes.Carousel ? (
            <HtmlEditor
              key={1}
              description="Test Data"
              initialHtml={testData || ''}
              onChange={newHtml => testDataChanged(newHtml)}
              fontSize={serviceConfig.fontSize}
              fontFamily={serviceConfig.fontFamily}
            />
          ) :
            <TextField
              type='text'
              label='Test Data'
              value={testData || ''}
              onChange={e => testDataChanged(e.target.value)}
            />
          }
          <FormHelperText>{testDataValidationMessage}</FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl
          className={classes.formControl}
          error={!inputTypeIdIsValid}>
          <InputLabel>Type</InputLabel>
          <Select
            disabled={isEditMode}
            value={inputTypeId}
            onChange={e => typeChanged(e.target.value)}>
            <MenuItem value={0} disabled>
              Please Select
            </MenuItem>
            <MenuItem value={1}>Free Text</MenuItem>
            <MenuItem value={2}>Date Picker</MenuItem>
            <MenuItem value={3}>Time Picker</MenuItem>
            <MenuItem value={4}>Drop Down</MenuItem>
            <MenuItem value={5}>Html Editor</MenuItem>
            <MenuItem value={6}>Carousel</MenuItem>
            {/* <MenuItem value={7}>Address</MenuItem> temp removal for new address editable*/}
          </Select>
          <FormHelperText>{inputTypeIdValidationMessage}</FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isMandatory}
                onChange={e => isMandatoryChanged(e.target.checked)}
                color='primary'
              />
            }
            label='Is Mandatory?'
          />
        </FormControl>
      </div>
      {isEditMode ? (
        <div>
          <FormControl className={classes.formControl}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isArchived}
                  onChange={e => isArchivedChanged(e.target.checked)}
                  color='primary'
                />
              }
              label='Archived?'
            />
          </FormControl>
        </div>

      ) : null}
    </AdminCard>
  )
}

export default EditableRegionFormOverview
